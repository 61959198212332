import React from 'react';
import "../Components_SCSS/Cost.scss";
import "../Components_SCSS/firebase.scss";
import CostForm from "./firebase";
const Cost = () => (

    <>
        <div className="cost_container">
            <h3>Cennik</h3>
            <div className="cost_content">
                <div className="cost">
                    <h1>Cennik Druku Pionowego</h1>
                    <h2>Wydruk pionowy na dowolnej powierzchni</h2>
                    <p>Koszt już od 299 zł w zależności od wymiarów nadruku.</p>
                    <p>Ze względu na:</p>
                    <ul>
                        <li>rozmiar i jakość zamówionej grafiki,</li>
                        <li>pracę grafika przy edycji obrazu,</li>
                        <li>odległość do miejsca realizacji zamówienia,</li>
                    </ul>
                    <p>Każdy projekt wyceniamy indywidualnie z możliwością negocjacji ceny.</p>
                </div>
                <div id="cost_form">
                    <h1>Formularz kontaktowy</h1>
                    <p>Wypełnij formularz, a my skontaktujemy się z Tobą w celu ustalenia szczegółów.</p>
                    <CostForm />

                    <div id="info"></div>
                </div>

            </div>
        </div>
    </>

);

export default Cost;