import React from 'react';
import "../Components_SCSS/Gallery.scss"
import {useState, useEffect} from 'react';
import gallery1 from "../assets/Gallery/bomba.webp";
import gallery2 from "../assets/Gallery/czołg.webp";
import gallery3 from "../assets/Gallery/las.webp";
import gallery4 from "../assets/Gallery/las_strażak.webp";
import gallery5 from "../assets/Gallery/Maszyna.webp";
import gallery6 from "../assets/Gallery/statek.webp";
import gallery7 from "../assets/Gallery/motor.webp";


function Gallery() {


    const images = [
        gallery1,
        gallery2,
        gallery3,
        gallery4,
        gallery5,
        gallery6,
        gallery7,
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false); // Dodajemy zmienną isPaused

    const prevImage = () => {
        if (!isPaused) {
            setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
        }
    };

    const nextImage = () => {
        if (!isPaused) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }
    };

    const togglePause = () => {
        setIsPaused((prevIsPaused) => !prevIsPaused);
    };

    useEffect(() => {
        if (!isPaused) {
            const intervalId = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 4000);
            return () => clearInterval(intervalId);
        }
    }, [isPaused]);


    return (
        <>
            <div className="gallery">
                <div className="gallery_text">
                    <h3>Galeria</h3><p>
                    Jestesmy na poczatku przygody z drukiem, zamieszczamy nasze projekty, z waszym udzialem galeria
                    bedzie sie powiekszać.
                    Zobacz jak wyglądają nasze prace w
                    rzeczywistości.</p>
                </div>
                <div className="gallery-container">
                    <button className="arrow-button prev-button" onClick={prevImage}>
                        &lt; {/* Strzałka w lewo */}
                    </button>
                    <div className="gallery-image prev-image" style={{opacity: 0.5}}>
                        <img
                            src={images[currentIndex === 0 ? images.length - 1 : currentIndex - 1]}
                            alt="Poprzedni obraz"
                        />
                    </div>
                    <div className="gallery-image current-image" style={{opacity: 1}}>
                        <img src={images[currentIndex]} alt="Aktualny obraz"/>
                    </div>
                    <div className="gallery-image next-image" style={{opacity: 0.5}}>
                        <img src={images[(currentIndex + 1) % images.length]} alt="Następny obraz"/>
                    </div>
                    <button className="arrow-button next-button" onClick={nextImage}>
                        &gt; {/* Strzałka w prawo */}
                    </button>
                </div>
                <div className="pause">
                    <button className="pause-button" onClick={togglePause}>
                        {isPaused ? 'Start' : 'Stop'} {/* Przycisk Play/Pause */}
                    </button>
                </div>
            </div>
        </>
    );
}

export default Gallery;