import React from 'react';
import "../Components_SCSS/Cooperation.scss"
import "../Components_SCSS/Main.scss"
import "../Components_SCSS/Wiz.scss"
import CooperationCard from "./Cooperation_card";
import img1 from "../assets/Icon-Color_1.webp"
import img2 from "../assets/Icon-Color_2.webp"
import img3 from "../assets/Icon-Color_3.webp"
import img4 from "../assets/Icon-Color_4.webp"


function Cooperation() {
    return (
        <div className="cooperation container">
            <h2 className='cooperation_title'>Współpraca</h2>
            <p>Gwarantujemy przejrzyste warunki współpracy. W czterech krokach przedstawiamy jak wygląda proces
                realizacji zamówienia.</p>

            <div className="cooperation__steps">
                <CooperationCard image={img1} title="Twoja Wizja Ściany"
                                 description="Zgłaszasz do nas swój pomysł, a nasi graficy posłużą Ci w odnalezieniu odpowiedniej grafiki."/>
                <CooperationCard image={img2} title="Wizualizacja kompozycji"
                                 description="Przesyłasz do nas wybrany wzór wraz ze zdjęciem oraz wymiarami Twojej pionowej powierzchni."/>
                <CooperationCard image={img4} title="Nasza oferta"
                                 description="Przedstawiamy Ci prostą wizualizację Twojej kompozycji, wyceniamy i uzgadniamy termin realizacji usługi."/>
                <CooperationCard image={img3} title="Realizacja"
                                 description="Przyjeżdzamy i zamieniamy Twój projekt w rzeczywistość"/>
            </div>
            <div className="wiz">

                <div className="container_wiz">
                    <p className="wiz_description">Nie masz pomysłu na własny projekt? Nic straconego! Banki obrazów
                        oferują szeroki wybór profesjonalnych fotografii, ilustracji i grafik z różnych kategorii, co
                        pozwali Ci znaleźć wysokiej jakości projekt graficzny zgodny z Twoimi oczekiwaniami</p>
                    <div className="wiz_link">
                        <a href="https://www.shutterstock.com/pl/"> <img
                            src="https://stgu.pl/stgu_files/Image/o%20stgu/Shutterstock.png" alt="" width="250px"
                            height="150px"/>
                        </a>
                        <a href="https://www.pexels.com/pl-pl/"> <img
                            src="https://images.ctfassets.net/0exzujcgu96f/xrduV3eHfAj4Y5TVF3XUY/5ba22b9a5c3124c49a5b163e46fa7d41/pexels-logo.png"
                            style={{backgroundColor: "white", padding: "1rem"}} width="280px" height="130px" alt=""/>
                        </a>
                        <a href="https://unsplash.com/"> <img
                            src="https://miro.medium.com/v2/resize:fit:4000/1*vQ5EsgnJkANWb5fktHPwnw.jpeg"
                            width="250px" height="150px" alt=""/>
                        </a>
                        <a href="https://pixabay.com/pl/"> <img
                            src="https://cdn.pixabay.com/photo/2020/06/21/19/02/pixabay-5326292_960_720.png"
                            width="250px" height="150px" alt=""/>
                        </a>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Cooperation;