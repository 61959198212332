import React from 'react';
import '../Components_SCSS/For.scss';
import For_Component from "./For_Component";
import img1 from '../assets/for/restauracja-1.jpg';
import img2 from '../assets/for/dom.jpg';
import img3 from '../assets/for/szpital.jpg';
import img4 from '../assets/for/firma.jpg';
import img5 from '../assets/for/fryzjer.jpg';
import img6 from '../assets/for/kosmetyczka.jpg';
import img7 from '../assets/for/miasto.jpg';
import img8 from '../assets/for/przedszkole.jpg';


function For() {
    return (
        <section className='for'>
            <div className="for_container">
                <div className="for_container_text">
                    <h2 className='for_text'>Do kogo skierowana jest nasza oferta?</h2>
                    <p>Pamiętaj, że zapewniamy Ci profesjonalne wsparcie na każdym etapie procesu realizacji projektu.
                        Dbamy o każdy detal aby uzyskać efekt końcowy dokładnie taki, jakiego oczekujesz.</p>
                </div>
                <div className="for_container_boxs container">
                    <For_Component img={img4} description='Dla firm' text='Druk ścienny to doskonałe narzędzie do prezentacji logo firmy, jej wartości i misji. Pozwala na dostosowanie przestrzeni do charakteru przedsiębiorstwa wzmacniając jego wizerunek.'/>
                    <For_Component img={img2} description='Dom' text='Nieograniczone możliwości kreatywnego aranżowania przestrzeni od sypialni po salon. Narzędzie, które pozwala mieszkańcom wyrazić swoją kreatywność, dostosować przestrzeń do własnych potrzeb by stworzyć wnętrze, które odzwierciedla indywidualny styl i życiowe wartości.'/>
                    <For_Component img={img1} description='Restauracje i puby' text='Druk ścienny może być wykorzystywany do prezentacji menu w atrakcyjny sposób, przyciągając uwagę klientów. Grafiki tematyczne i dekoracje ścienne pomogą kształtować unikalną atmosferę w lokalu.'/>
                    <For_Component img={img6} description='Salony kosmetyczne' text='Spersonalizowane grafiki pomagają w budowaniu rozpoznawalności marki. Podkreślają profesjonalizm i dbałość o detale, co może wpływać na lojalność klientów.'/>
                    <For_Component img={img5} description='Salony fryzjerskie i barberskie' text='Druk ścienny pozwala na łatwe wprowadzenie zmian w aranżacji, tworząc stylowy i modny wystrój salonu. Projekty graficzne mogą pomóc w prezentacji oferty i znalezienia inspiracji dla klientów.'/>
                    <For_Component img={img8} description='Przedszkola i żłobki' text=' Druk ścienny z edukacyjnymi grafikami może wspomagać proces nauki i rozwijania umiejętności u najmłodszych. Tworzy przyjazne otoczenie sprzyjające rozwojowi dzieci oraz ich pozytywnemu samopoczuciu.'/>
                    <For_Component img={img3} description='Szpitale' text='Druk ścienny ułatwia przekazywanie informacji dotyczących procedur i bezpieczeństwa. Stwarza przyjemne otoczenie w salach szpitalnych wspierając proces zdrowienia pacjentów.'/>
                    <For_Component img={img7} description='Urzędy' text='Grafiki ścienne mogą służyć jako profesjonalne oznakowanie poszczególnych działów, biur czy pomieszczeń. Grafiki informacyjne o lokalnych wydarzeniach czy inicjatywach społecznych mogą budować więź z lokalną społecznością.'/>
                </div>
            </div>
        </section>
    );
}

export default For;