import React from 'react';
import '../Components_SCSS/Main.scss';
import '../Components_SCSS/How_work.scss';
import TimerIcon from '@mui/icons-material/Timer';
import ClearIcon from '@mui/icons-material/Clear';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CheckIcon from '@mui/icons-material/Check';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GroupIcon from '@mui/icons-material/Group';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import HowWorkBox from "./How_work_box";
import HowWorkBoxSecondary from "./How_work_box_secondary";


const HowWork = () => {
    return (
        <>
            <div className="how_work container">
                <div className="how_work_box">
                    <h2 className="how_work_title">Jak działamy?</h2>
                    <h3 className="how_work_description">W procesie drukowania wykorzystujemy nowoczesną technologię
                        druku
                        UV, w której farby są utrwalane za pomocą promieniowania ultrafioletowego.<br></br>
                        Korzyści zastosowania technologii druku UV:</h3>
                </div>
                <div className="how_work_content_box container">
                    <HowWorkBox icon={<WorkspacePremiumIcon sx={{fontSize: 90}}/>}
                                title="Najwyższa jakość druku"
                                description="Technologia UV jest niezwykle skuteczna przy drukowaniu małych czcionek oraz szczegółowych grafik"
                                icon2={<TimerIcon sx={{fontSize: 90}}/>}
                                title2="Szybkość utrwalania"
                                description2="W krótkim czasie otrzymujemy gotową kompozycję na ścianie (średni czas wydruku to około 35 minut na 1m^2)"
                                icon3={<ClearIcon sx={{fontSize: 90}}/>}
                                title3="Bezinwazyjna realizacja projektu"
                                description3="Bezinwazyjna realizacja projektu bez użycia klejów, farb, wiertarek, bez konieczności zabezpieczania powierzchni"/>
                    <HowWorkBoxSecondary icon={<CheckIcon sx={{fontSize: 90}}/>}
                                         title="Odporność na ścieranie, blaknięcie i zabrudzenia"
                                         description="Wydrukowane materiały są trwałe i zachowują jakość przez długi okres, również przy wysokiej ekspozycji na słońce"
                                         icon1={<ViewInArIcon sx={{fontSize: 90}}/>}
                                         title1="Efekt trójwymiarowości"
                                         description1="Farby UV dodają głębi i atrakcyjności wizualnej, alternatywa dla fototapet oraz odpadających naklejek"
                    />
                    <HowWorkBox icon={<VerifiedUserIcon sx={{fontSize: 90}}/>}
                                title="Bezpieczeństwo i przyjazność środowiskowa"
                                description="Farby UV są bezwonne oraz nie zawierają lotnych związków organicznych"
                    icon2={<GroupIcon sx={{fontSize: 90}}/>}
                                title2="Wielobranżowość"
                                description2="Zastosowanie w wielu branżach, ofertę kierujemy zarówno dla osób prywatnych jak i przedsiębiorstw, jednostek samorządowych, stowarzyszeń, itp."
                    icon3={<WallpaperIcon sx={{fontSize: 90}}/>}
                                title3="Możliwość druku na dowolnej powierzchni"
                                description3="Druk na innych powierzchniach - otwieramy drzwi do różnorodnych zastosowań"

                    />


                </div>
            </div>
        </>
    )
        ;
}

export default HowWork;