import './App.scss';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from "./Components_JSX/Home";

function App() {
    return (
        <div className="app">
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>

                </Routes>
            </Router>


        </div>
    );
}

export default App;
