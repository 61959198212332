import React from 'react';
import '../Components_SCSS/Contact.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope, faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/mbproject-1.webp";

const Contact = () => (

    <div className="contact">
        <div className="contact_container">
            <h3>Kontakt</h3>
            <iframe className="contact_map"
                    title="myFrameMap"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3646.821047098478!2d19.429019493491328!3d52.40738175032704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b81380a3909a5%3A0x607af5e1b56b72f0!2sOsada%2013B%2C%2009-500%20Osada!5e0!3m2!1spl!2spl!4v1695124774533!5m2!1spl!2spl"></iframe>
        </div>
        <footer className='footer'>
            <div className="footer_left_box">
                <img src={logo} alt=""/>
            </div>
            <div className="footer_middle_box">
                <div>
                    <a href={"https://www.facebook.com/profile.php?id=61550536718664&sk"} target="_blank"
                       rel="noreferrer">
                        <FontAwesomeIcon className="social_facebook" icon={faFacebookF}/>
                    </a>
                    <a href='https://www.tiktok.com/@m.b..project?is_from_webapp=1&sender_device=pc' target="_blank"
                       rel="noreferrer">
                        <FontAwesomeIcon className="social_tiktok" icon={faTiktok}/>
                    </a>
                </div>
                <span>&copy; 2023 mb3dproject.pl</span>
            </div>
            <div className="footer_right_box">
                <div className='box_local'>
                    <FontAwesomeIcon icon={faLocationDot}/>
                    <p>Osada 13B</p>
                    <p>09-500</p>
                    <p>Gostynin</p>


                </div>
                <div className='box_address'>

                    <FontAwesomeIcon icon={faPhone}/>
                    <a href="tel:517-651-000">517-651-000</a>
                    <a href="tel:517-651-000">517-652-000</a>
                    <FontAwesomeIcon icon={faEnvelope}/>
                    <p>
                        <a href="mailto:mb3dproject@gmail.com">mb3dproject@gmail.com</a>
                    </p>
                </div>



            </div>
        </footer>
    </div>
);

export default Contact;