import React from 'react';
import '../Components_SCSS/Offer.scss';
import '../Components_SCSS/Main.scss';
import offer1 from '../assets/Ściana.webp';
import offer2 from '../assets/Drewno.webp';
import offer3 from '../assets/Beton.webp';
import offer4 from '../assets/Szkło.webp';
import offer5 from '../assets/Płótno.png';
import offer6 from '../assets/Banner.webp';
import offer7 from '../assets/Cegła.jpeg';
import offer8 from '../assets/Inne.webp';


const Offer = () => {
    return (
        <>
            <div className="offer">
                <h2>Nasza oferta</h2>
                <h3>Nasza oferta drukarska obejmuje szeroki zakres usług,
                    dostosowanych do Twoich potrzeb.<br></br> Oferujemy wysoką jakość druku w
                    konkurencyjnych cenach.</h3>
            </div>

                <div className="section container">
                    <div className="block block-first">
                        <div className="image">
                            <img src={offer1} alt="Obrazek 1"/>
                            <p>Druk na ścianie</p>
                        </div>
                        <div className="image">
                            <img src={offer2} alt="Obrazek 2"/>
                            <p>Druk na drewnie</p>
                        </div>
                        <div className="image">
                            <img src={offer3} alt="Obrazek 3"/>
                            <p>Druk na betonie</p>
                        </div>
                        <div className="image">
                            <img src={offer4} alt="Obrazek 4"/>
                            <p>Druk na szkle</p>
                        </div>
                    </div>
                    <div className="block block-third">
                        <div className="image">
                            <img src={offer5} alt="Obrazek 5"/>
                            <p>Druk na płótnie</p>
                        </div>
                        <div className="image">
                            <img src={offer6} alt="Obrazek 6"/>
                            <p>Druk na banerach</p>
                        </div>
                        <div className="image">
                            <img src={offer7} alt="Obrazek 7"/>
                            <p>Druk na cegle</p>
                        </div>
                        <div className="image">
                            <img src={offer8} alt="Obrazek 8"/>
                            <p>Inne powierzchnie</p>
                        </div>
                    </div>
                </div>

        </>
    );
};

export default Offer;