import React from 'react';
import '../Components_SCSS/For_Component.scss';

function ForComponent({img, description, text, alt}) {
    return (
        <section className='for_component'>
            <h3>{description}</h3>
            <div className="for_component_box">
                <img src={img} alt={alt}/>
                <p>{text}</p>
            </div>


        </section>
    );
}

export default ForComponent;