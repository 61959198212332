import React from "react";
import "../Components_SCSS/Header.scss";
import {Link as SwitchLink} from "react-scroll"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone} from '@fortawesome/free-solid-svg-icons'
import {faFacebookF, faTiktok} from '@fortawesome/free-brands-svg-icons'
import prinnterr from "../assets/printer1.mp4"
import logo from "../assets/mbproject-1.webp"

const Header = () => {
    return (
        <div className="header">
            <video
                className="header_video"
                autoPlay
                muted
                loop
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '95rem',
                    objectFit: 'cover',
                    zIndex: '-1',

                }
                }
            >
                <source src={prinnterr} type="video/mp4"/>
            </video>

            <div className="nav">
                <div className="nav_logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div className="nav__menu">
                    <SwitchLink
                        to="header"
                        smooth={true}
                        duration={1000}
                        className="menu_link">Start</SwitchLink>

                    <SwitchLink
                        to="about_us"
                        smooth={true}
                        duration={1000}
                        className="menu_link">O nas</SwitchLink>

                    <SwitchLink
                        to="how_work"
                        smooth={true}
                        duration={1000}
                        className="menu_link">Jak działamy?</SwitchLink>

                    <SwitchLink to="offer"
                                smooth={true}
                                duration={1000}
                                className="menu_link">Oferta</SwitchLink>

                    <SwitchLink to="cooperation_title"
                                smooth={true}
                                duration={1000}
                                className="menu_link">Współpraca</SwitchLink>

                    <SwitchLink to="for"
                                smooth={true}
                                duration={1000}
                                className="menu_link">Dla kogo</SwitchLink>
                    <SwitchLink to="gallery"
                                smooth={true}
                                duration={1000}
                                className="menu_link">Galeria</SwitchLink>

                    <SwitchLink to="cost_container"
                                smooth={true}
                                duration={1000}
                                className="menu_link">Cennik</SwitchLink>

                    <SwitchLink to="footer"
                                smooth={true}
                                duration={1000}
                                className="menu_link">Kontakt</SwitchLink>
                </div>
                <div className="nav__media">
                    <div className="media_phone">
                        <FontAwesomeIcon className="media_icon_phone" icon={faPhone}></FontAwesomeIcon>
                        <div className="media_number">
                            <a className='media_number_phone' href="tel:517-651-000">517-651-000</a>
                            <a className='media_number_phone' href="tel:517-651-000">517-652-000</a>
                        </div>

                    </div>
                    <div className="media_social">
                        <a href={"https://www.facebook.com/profile.php?id=61550536718664&sk"} target="_blank"
                           rel="noreferrer">
                            <FontAwesomeIcon className="social_facebook" icon={faFacebookF}/>
                        </a>
                        <a href='https://www.tiktok.com/@m.b..project?is_from_webapp=1&sender_device=pc' target="_blank"
                           rel="noreferrer">
                            <FontAwesomeIcon className="social_tiktok" icon={faTiktok}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="header__content">
                <div className="content_left">
                    <h1 className="content_title">M.B.PROJECT - Druk ścienny</h1>
                    <h2 className="content_subtitle">Przestrzeń pełna inspiracji</h2>
                    <p className="content_text">Tworzymy wyjątkowe projekty graficzne na terenie<br></br> Kutna, Płocka, Łodzi, Warszawy i wielu innych miastach.
                    </p>
                    <div className="content_buttons">
                        <SwitchLink to="offer"
                                    smooth={true}
                                    duration={1000}
                                    className="content_button_first">Oferta</SwitchLink>
                        <SwitchLink to="footer"
                                    smooth={true}
                                    duration={1000}
                                    className="content_button_secondary">Kontakt</SwitchLink>
                    </div>
                </div>
                <div className="content_right">

                </div>
            </div>
        </div>


    );
}

export default Header;