import React, {useRef} from "react";
import emailjs from "@emailjs/browser";
import "../Components_SCSS/firebase.scss"
import {render} from 'react-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
const CostForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_8d9mzbs",
                "template_kym3qgk",
                form.current,
                "B4idImzmb0zNesYQm"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    const inputName = document.querySelector('input[name="user_name"]');
                    const inputEmail = document.querySelector('input[name="user_email"]');
                    const inputPhone = document.querySelector('input[name="user_phone"]');
                    const inputMessage = document.querySelector('textarea[name="message"]');


                    if (inputName && inputEmail && inputPhone) {
                        inputName.value = '';
                        inputEmail.value = '';
                        inputPhone.value = '';
                        inputMessage.value = '';
                    }
                    render(
                        <Stack sx={{width: '100%'}}  spacing={2}>
                            <Alert severity="success" sx={{fontSize:"1.4rem"}} className="alert">Formularz został pomyślnie wysłany!</Alert>
                        </Stack>,
                        document.getElementById('info')
                    );
                }
                ,
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <>
            <form ref={form} onSubmit={sendEmail}>
                <div className="formularz">
                    <div className="data">
                        <label>Imię i nazwisko</label>
                        <input type="text" name="user_name"/>
                        <label>Email</label>
                        <input type="email" name="user_email"/>
                        <label>Telefon kontaktowy</label>
                        <input type="text" name="user_phone"/>
                    </div>
                    <div className="message">
                        <label>Wiadomość</label>
                        <textarea name="message"/>
                    </div>
                </div>
                <input type="submit" value="Wyślij"/>

            </form>
        </>
    );
};

export default CostForm;
