import React from "react";
import Header from "./Header";
import AboutUs from "./About_Us";
import HowWork from "./How_work";
import Offer from "./Offer";
import Cooperation from "./Cooperation"
import Gallery from "./Gallery";
import Cost from "./Cost";
import Contact from "./Contact";
import For from "./For";


function Home() {
    return (
        <>

            <Header/>
            <AboutUs/>
            <HowWork/>
            <Offer/>
            <Cooperation/>
            <For/>
            <Gallery/>
            <Cost/>
            <Contact/>



        </>
    )
}

export default Home;