import React from 'react';
import '../Components_SCSS/Cooperation_card.scss';

function CooperationCard({image, title, description}) {
    return (
        <div className="step">
            <div className="step_image">
                <img src={image} alt="Obrazek 1"/>
            </div>
            <div className="step_text">
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>);
}

export default CooperationCard;