import React from 'react';
import "../Components_SCSS/AboutUs.scss";
import "../Components_SCSS/Main.scss";

const AboutUs = () => {
    return (
        <div className="about_us">
            <div className="about_us_box">
                <h2 className="about_us_title">Krótko o nas</h2>
                <h3 className="about_us_text">Specjalizujemy się w tworzeniu unikalnych nadruków ściennych.</h3>
                <div className="about_us_content_box container" >
                    <div className="box_right">

                    </div>
                    <div className="box_left">
                        <p className="about_us_description">Z M.B. Project przekształcisz zwykłą przestrzeń w miejsce
                            pełne
                            charakteru i inspiracji poprzez kreatywne projekty graficzne.
                            Nasz zespół grafików pracuje wraz z Tobą nad stworzeniem oryginalnych kompozycji.
                            Jesteśmy gotowi pomóc w wyborze idealnego projektu i dostosowaniu go do konkretnych wymagań.
                            Współpracujemy z klientami na każdym etapie projektu - od koncepcji, przez projektowanie, aż
                            do
                            finalnej realizacji.
                            Jesteśmy źródłem inspiracji do wyrażania własnej osobowości poprzez tworzenie przestrzeni
                            pełnych znaczenia i emocji dla naszych klientów.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default AboutUs;