import React from 'react';
import '../Components_SCSS/How_work.scss';
import "../Components_SCSS/Main.scss";
const HowWorkBox = ({icon, title, description, icon2, title2, description2,icon3, title3, description3}) => {
    return(

        <div className="how_work_content_box container">
            <div className="how_work_content_box_first">
                <div className="how_work_content_box_left">
                    <div className="box_left_content">
                        {icon}
                    </div>
                </div>
                <div className="how_work_content_box_right">
                    <div className="box_right_content">
                        <h3 className="how_work_content_box_title">{title}</h3>
                        <p className="how_work_content_box_description">{description}</p>
                    </div>
                </div>
            </div>
            <div className="how_work_content_box_first">
                <div className="how_work_content_box_left">
                    <div className="box_left_content">
                        {icon2}
                    </div>
                </div>
                <div className="how_work_content_box_right">
                    <div className="box_right_content">
                        <h3 className="how_work_content_box_title">{title2}</h3>
                        <p className="how_work_content_box_description">{description2}</p>
                    </div>
                </div>
            </div>
            <div className="how_work_content_box_first">
                <div className="how_work_content_box_left">
                    <div className="box_left_content">
                        {icon3}
                    </div>
                </div>
                <div className="how_work_content_box_right">
                    <div className="box_right_content">
                        <h3 className="how_work_content_box_title">{title3}</h3>
                        <p className="how_work_content_box_description">{description3}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default HowWorkBox;